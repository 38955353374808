import React from "react";
import styles from "../Styles/Representatives.module.css";
import Heading from "./Common/Heading";
import RepresentCard from "./Util/RepresentCard";

import { wardens } from "../Data/wardens.json";

class Representatives extends React.Component {
    render() {
        let items = [];
        for (let i = 0; i < wardens.length; i++) {
            items.push(
                <RepresentCard
                key={i}
                style={{margin: '0 auto'}}
                img={wardens[i].img}
                alt={wardens[i].alt}
                name={wardens[i].name}
                designation={wardens[i].designation}
                number={wardens[i].number}
                email={wardens[i].email}
                contact={wardens[i].contact}
                />
            )
        }
        return (
            <>
            <Heading title="Representatives" />
            <div className={styles.representatives}>
                {items}
            </div>
            </>
        );
    }
}

export default Representatives;
