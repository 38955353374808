import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScrollToTop from "./Util/ScrollToTop";

import Header from "./Common/Header";
import Footer from "./Common/Footer";
import About from "./About";
import Representatives from "./Representatives";
import Staff from "./Staff";
import Supervisor from "./Supervisor";
import Information from "./Information";

import Home from "./Home";
import Rules from "./Rules";
import Team from "./Team";

import "../Styles/App.css";
import ChatBot from "./Common/ChatBot";
import Gallery from "./Gallery";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            links: {
                home: `/`,
                home2: `/home`,
                profile: `/profile`,
                about: `/about`,
                representatives: `/representatives`,
                info: `/information/*`,
                rules: `/rules-regulations`,
                team: `/team`,
                staff: `/staff`,
                supervisor: `/supervisor`,
                gallery: `/gallery`
            },
            // tryingLogin: true,
            // isLoggedIn: false,
            // fetchingDetails: false,
            // fetchedDetails: false,
            // details: undefined,
            // isAdmin: false,
        };

        // axios({
        //     method: "POST",
        //     url: "/api/checklogin",
        //     withCredentials: true,
        //     xsrfCookieName: "csrf-token",
        //     xsrfHeaderName: "csrf-token",
        // })
        //     .then((resp) => {
        //         if (resp.status !== 200) {
        //             console.log(
        //                 `Axios Error ${resp.status}: ${resp.statusText}`
        //             );
        //             console.log(resp.data.success);
        //             console.log(resp.data.err);
        //             this.setLoggedIn(false, false);
        //         } else {
        //             console.log(resp.data.success);
        //             console.log(resp.data.msg);
        //             this.setLoggedIn(resp.data.isLoggedIn, resp.data.isAdmin);
        //         }
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //         this.setLoggedIn(false, false);
        //     });
    }

    // setLoggedIn = (isLoggedIn, isAdmin) => {
    //     this.setState({
    //         isLoggedIn,
    //         tryingLogin: false,
    //         fetchingDetails: isLoggedIn,
    //         fetchedDetails: false,
    //         details: undefined,
    //         isAdmin,
    //     });
    //     if (isLoggedIn) this.fetchDetails();
    // };

    // logoutHandler = () => {
    //     axios({
    //         method: "POST",
    //         url: "/api/logout",
    //         xsrfCookieName: "csrf-token",
    //         xsrfHeaderName: "csrf-token",
    //     })
    //         .then((resp) => {
    //             if (resp.status !== 200) {
    //                 console.log(
    //                     `Axios Error ${resp.status}: ${resp.statusText}`
    //                 );
    //                 console.log(resp.data.success);
    //                 console.log(resp.data.err);
    //                 alert(resp.data.err);
    //             } else if (resp.data.success && !resp.data.isLoggedIn) {
    //                 console.log(resp.data.success);
    //                 console.log(resp.data.isLoggedIn);
    //                 this.setLoggedIn(false, false);
    //             } else {
    //                 console.log("Couldn't log out");
    //                 console.log(resp.response.success);
    //                 console.log(resp.response.err);
    //                 alert("Couldn't log out");
    //             }
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //             alert("Couldn't log out");
    //         });
    // };

    // loginHandler = (username, password) => {
    //     axios({
    //         method: "POST",
    //         url: "/api/login",
    //         data: {
    //             username,
    //             password,
    //         },
    //         xsrfCookieName: "csrf-token",
    //         xsrfHeaderName: "csrf-token",
    //     })
    //         .then((resp) => {
    //             if (resp.status !== 200) {
    //                 console.log(
    //                     `Axios Error ${resp.status}: ${resp.statusText}`
    //                 );
    //                 console.log(resp.data.success);
    //                 console.log(resp.data.err);
    //                 this.setLoggedIn(false, false);
    //             } else {
    //                 console.log(resp.data.success);
    //                 console.log(resp.data.msg);
    //                 this.setLoggedIn(true, resp.data.isAdmin);
    //             }
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //             this.setLoggedIn(false, false);
    //         });
    // };

    // fetchDetails = async () => {
    //     this.setState({
    //         fetchingDetails: true,
    //         fetchedDetails: false,
    //         details: undefined,
    //     });
    //
    //     axios({
    //         method: "GET",
    //         url: "/api/profile",
    //         withCredentials: true,
    //         xsrfCookieName: "csrf-token",
    //         xsrfHeaderName: "csrf-token",
    //     })
    //         .then((resp) => {
    //             const data = resp.data;
    //             if (
    //                 !(resp.status === 200 || resp.status === 304) ||
    //                 !data.success
    //             ) {
            //         console.log(
            //             `Couldn't fetch details successfully: ${
            //                 data.err || data.msg
            //             }`
            //         );
            //         this.setState({
            //             fetchingDetails: false,
            //             fetchedDetails: false,
            //             details: undefined,
            //         });
            //     } else {
            //         this.setState({
            //             fetchingDetails: false,
            //             fetchedDetails: true,
            //             details: data.data,
            //         });
            //     }
            // })
            // .catch((err) => {
            //     console.log(err);
    //             this.setState({
    //                 fetchingDetails: false,
    //                 fetchedDetails: false,
    //                 details: undefined,
    //             });
    //         });
    // };
    //
    // changeMessHandler = async (mess) => {
    //     axios({
    //         method: "PUT",
    //         url: "/api/changemess",
    //         withCredentials: true,
    //         xsrfCookieName: "csrf-token",
    //         xsrfHeaderName: "csrf-token",
    //         data: {
    //             mess,
    //         }
    //     })
    //     .then(resp => {
    //         if(!resp.data.success) {
    //             console.log(resp.data.msg || resp.data.err);
    //             alert("Couldn't change mess!");
    //         } else {
    //             this.fetchDetails();
    //         }
    //     })
    //     .catch(err => {
    //         console.log(err);
    //         alert("Couldn't change mess!");
    //     });
    // }

    render() {
        // console.log(this.state);
        return (
            <div className="root-container">
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <ScrollToTop />
                    <Header
                        links={this.state.links}
                        isLoggedIn={this.state.isLoggedIn}
                        logoutHandler={this.logoutHandler}
                    />
                    <Routes>
                        <Route
                            path={this.state.links.home}
                            element={<Home />}
                        />
                        <Route
                            path={this.state.links.home2}
                            element={<Home />}
                        />
                        <Route
                            path={this.state.links.about}
                            element={<About />}
                        />
                        <Route
                            path={this.state.links.representatives}
                            element={<Representatives />}
                        />
                        <Route
                            path={this.state.links.rules}
                            element={<Rules />}
                        />
                        
                        <Route
                            path={this.state.links.team}
                            element={<Team />}
                        />
                        <Route
                            path={this.state.links.info}
                            element={<Information />}
                        />
                        <Route
                            path={this.state.links.supervisor}
                            element={<Supervisor />}
                        />
                        <Route
                            path={this.state.links.staff}
                            element={<Staff />}
                        />
                        <Route
                            path={this.state.links.gallery}
                            element={<Gallery />}
                        />
                    </Routes>

                    <ChatBot />

                    <Footer />
                </BrowserRouter>
                {/* <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>

                    <Route
                        path={this.state.links.info}
                        element={<Information />}
                    />
                </Routes>
                </BrowserRouter> */}

            </div>
        );
    }
}

export default App;
