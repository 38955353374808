import React from "react";
import styles from "../Styles/Team.module.css";
import Heading from "./Common/Heading";
// import TeamCard from "./Util/TeamCard";

import { team } from "../Data/team.json";

class Representatives extends React.Component {
    render() {
        // let items = [];
        // for (let i = 0; i < team.length; i++) {
        //     items.push(
        //         <TeamCard
        //         key={i}
        //         style={{margin: '0 auto'}}
        //         img={team[i].img}
        //         alt={team[i].alt}
        //         name={team[i].name}
        //         designation={team[i].designation}
        //         role={team[i].role}
        //         />
        //     )
        // }
        // return (
        //     <>
        //     <Heading title="Developer Team" />
        //     <div className={styles.representatives}>
        //         {items}
        //     </div>
        //     <div className={styles.end}>Done in collaboration with <a href="https://iris.nitk.ac.in" target="_">IRIS NITK</a></div>
        //     </>
        // );

        let it = [];
        for(let i of team) {
            it.push((
                <li key={i.name}>
                    <p>{i.name}</p>
                    <p>{i.designation}</p>
                    <p>{i.role}</p>
                </li>
            ));
        }

        return (
            <>
            <Heading title="Developer Team" />
            <div className={styles.representatives}>
                <div className={styles.card}>
                    <p>Website Developed by</p>
                    <ul className={styles.list}>
                        {it}
                    </ul>
                </div>
            </div>
            <div className={styles.end}>Done in collaboration with <a href="https://iris.nitk.ac.in" target="_">IRIS NITK</a></div>

            {/* <div className="row">
                <div className="col-lg-7">
                    <div style="float: left;" >
    
                        <img className="col-lg-3" src="" alt=""/>
                        <div className="col-lg-9">
                            <p><b class="head-name">The Batman </b><span class="year">2022</span></p>
                            <div class="content">
                                <p class="username"><i class="icon fas fa-user-circle"></i> &nbsp; Patrick Williams</p>
                                <p>The first half of this is pretty much perfect, from the madcap editing
                                     and storytelling to the magnificently realized musical numbers. The 
                                     second half took a bit of a tumble into bland melodrama (and the 
                                     duke is kind of a supreme dumbass for not realizing the play was 
                                     about him sooner) but overall it's a movie that I CANNOT believe I 
                                     liked as much as I did. </p>
                            </div>
                        </div>
        
                    </div>
                   </div> 
                </div> */}

            </>
        );
    }
}

export default Representatives;
