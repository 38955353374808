import React from "react";

import styles from "../../Styles/Util/HostelPopUp.module.css";
import { Link } from "react-router-dom";

const images = require.context("../../Images/Gallery/hostels/");

function getSigImage(props) {
    if(props.data.photoSphere) {
        return (
            <iframe
                title={props.data.text}
                src={props.data.photoSphere}
                className={styles.frame}
                loading={"lazy"}
                referrerpolicy={"no-referrer-when-downgrade"}
            >
            </iframe>
        );
    }

    return (
        <img
            src={images(`./${props.data.image}`)}
            alt="hostel"
            className={styles.image}
        />
    );
}

function HostelPopUp(props) {
    return (
        <>
            <Link to="../"><div className={styles.modal}></div></Link>

            <div className={styles.card}>

                {getSigImage(props)}

                <div className={styles.text}>{props.data.text}</div>
                <div className={styles.details}>
                    {/* <div style={{ textDecoration: "underline" }}>DETAILS</div>
                    <br />
                    <div>
                        Warden:{" "}
                        <Link to="/representatives" className={styles.warden}>
                            {props.data.warden}
                        </Link>
                    </div>
                    <div>Rooms: {props.data.rooms}</div>
                    <div>Mess Capacity: {props.data.mess}</div> */}

                <table className="table table-striped" style={{width: "80%",margin:'auto'}} >

                    <tbody>
                        <tr>
                        <th scope="row">Warden</th>
                        <td>{props.data.warden}</td>
                        </tr>
                        <tr>
                        <th scope="row">Supervisor</th>
                        <td>{props.data.supervisor}</td>
                        </tr>
                        <tr>
                        <th scope="row">Rooms</th>
                        <td>{props.data.rooms}</td>
                        </tr>
                    </tbody>
                </table>

                </div>
                <hr />

                <div className={styles.linkWrap}>
                    <Link className={styles.link} to="../">
                        <button style={{margin:'auto' }} className='btn btn-primary'>Close</button>
                    </Link>
                </div>

            </div>
        </>
    );
}

export default HostelPopUp;
