import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import YveBot from 'yve-bot/ui';
import 'yve-bot/ext/types/StringSearch';

import '../../Styles/Common/ChatBot.css';
import styles from "../../Styles/Common/ChatBot.module.css";

import { rules } from '../../Data/chatbot.json';

const ChatBot = () => {
    let navigate = useNavigate();
    let bot;
    let [visible, toggleVisible] = useState(false);

    useEffect(() => {
        bot = new YveBot(rules || [], {
            target: '.Chatbot',
            autoFocus: false,
        });

        bot.actions.define("navg", async (actionOptions, bot) => {
            navigate(actionOptions, {replace: true});
        });

        bot.start();
    }, []);

    return (
      <>
        <div className={styles.bottomRight}>
          <div className={`${visible ? "" : `${styles.invisible}`} ${styles.chatbot}`}>
            <div className={styles.topBar}>
              <button type="button" className={styles.ask} aria-label="Close" onClick={() => toggleVisible(!visible)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  fill="black" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </div>
            <div className="Chatbot" />
          </div>
          <button className={`${!visible ? "" : `${styles.invisible}`} ${styles.ask}`} onClick={() => {toggleVisible(!visible)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="90" fill="lightblue" stroke="black" className="bi bi-chat-left-dots-fill" viewBox="0 0 16 16">
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg>
          </button>
        </div>
      </>
    );
}

export default ChatBot;
