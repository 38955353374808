import React from "react";
import styles from "../Styles/Information.module.css";

import Card from "./Util/Card";
import HostelCard from "./Util/HostelCard";
import Heading from "./Common/Heading";

import { HostelBlocks } from "../Data/blocks.json";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import HostelPopUp from "./Util/HostelPopUp";
import { useTransition, animated } from "react-spring";

const Information = () => {
    const getTopInfo = () => {
        return (
            <div className={styles.introInfo}>
                <Card>
                    National Institute of Technology Karnataka, Surathkal (in
                    short – NITKS) is an autonomous Institute of the Government
                    of India under the Ministry of Human Recourse Development
                    imparting technical education. National Institute of
                    Technology Karnataka, Surathkal is one of the “Institutes of
                    National Importance” declared under the NIT Act – 2007 (Act
                    No.29 of 2007). NITK Hostel trust is looking after NITK
                    Hostel activities.
                </Card>
            </div>
        );
    };

    let hostelList = [];
    let hostelDialogue = [];
    for (let i = 0; i < HostelBlocks.length; i++) {
        hostelList.push(
            <Link key={i} to={HostelBlocks[i].url}>
                <HostelCard data={HostelBlocks[i]} />
            </Link>
        );

        hostelDialogue.push(
            <Route
                key={i}
                path={HostelBlocks[i].url}
                element={<HostelPopUp data={HostelBlocks[i]} />}
            />
        );
    }

    const location = useLocation();
    const transitions = useTransition(location, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    return (
        <>
            <Heading title="Hostel Information" />
            {getTopInfo()}
            <div className={styles.box}>{hostelList}</div>

            {transitions((props, item) => (
                <animated.div style={props}>
                    <Routes location={item}>{hostelDialogue}</Routes>
                </animated.div>
            ))}

            <Card
                title="Hostel Blocks:"
                subtitle="There are 16 Hostel Blocks with following accommodation"
            >
                <ol>
                    <li>Total number of boy’s hostel = 12</li>
                    <li>Total number of girl’s hostel = 4</li>
                    <li>Total number of Rooms for boys = 2893</li>
                    <li>Total number of rooms for girls = 750</li>
                </ol>
                <br />

                <p style={{ float: "left", margin: 0, width: "100%" }}>
                    There are 11 messes operating in various hostel blocks to
                    cater the needs of inmates. These are -
                </p>
                <ol>
                    <li>Karavali (I Block - Non Veg)</li>
                    <li>Aravali (II Block - Non Veg)</li>
                    <li>Vindhya (III Block - Non Veg-Outsource)</li>
                    <li>Satpura (IV Block - Non Veg-Outsource)</li>
                    <li>Nilgiri (V Block - Non Veg-Outsource)</li>
                    <li>Pushpagiri (PG Block - Veg-Outsource)</li>
                    <li>Sahyadri (VII Block-Veg)</li>
                    <li>Trishul (VIII Block Mess –Veg-Outsource)</li>
                    <li>Mega Block Mess (Veg-Outsource)</li>
                    <li>GH I block Mess, Ground Floor (Outsource)</li>
                    <li>GH II block Mess , First Floor (Outsource)</li>
                </ol>
                <br/>
                <p>
                    All the messes are provided with necessary
                    infrastructure to cater to the different food habits of the
                    students drawn from various parts of the country. There are
                    three night canteen facility available in Vindhya (III Block),
                    Sahyadri (VII Block) and Girls Hostel Blocks.
                </p>
                <br />

                <p>
                    All messes are managed by Hostel Administrative, with active
                    participation of the Student mess managers for preparation
                    of the menu and other issues.
                </p>
                <br />

                <p>
                    Total mess membership varies in every month. Out of the 11
                    messes, Vindhya (III Hostel), Satpura (IV Hostel) mess,
                    Nilgiri (V Hostel) mess, Trishul (VIII Hostel) mess,
                    Pushpagiri (PG Hostel) mess, Mega Hostel Mess (Chaitanya)
                    and Girls hostel messes are managed by the contractors.
                </p>
                <br />

                <p>
                    Laundry Facility is established in NITK Surahkal Hostels, Mega Tower II (Himalaya) and below the Girls' Cooperative
                    for benefit of the students.
                </p>
            </Card>
        </>
    );
};

export default Information;
