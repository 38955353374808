import React from "react";
import styles from "../../Styles/Util/HostelCard.module.css";

const images = require.context("../../Images/Gallery/hostels");

const HostelCard = (props) => {
    return (
            <div className={styles.card}>
                <img
                    src={ images(`./${props.data.image}`) }
                    alt="hostel"
                    className={styles.image}
                />
                <div className={styles.text}>
                  {props.data.text}
                </div>
            </div>
    );
}

export default HostelCard;
