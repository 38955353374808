import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

import styles from "../../Styles/Common/Header.module.css";
import Logo from "../../Images/Common/logo.svg";

class Header extends React.Component {
    state = { visible: false };

    clickHandle = () => {
        this.setState({ visible: !this.state.visible });
    };

    render() {
        return (
            <header className={styles.header}>
                {/* <div className={styles.topLeft}>
                    <Link to="/"><img src={Logo} alt="Logo" style={{paddingRight: 10, height: "100%", width: "auto"}}/></Link>
                    <Link className="text-uppercase text-decoration-none" to="/home">NITK Hostels</Link>
                </div>

                <Menu login={this.props.isLoggedIn} logoutHandler={this.props.logoutHandler} links={this.props.links} visible={this.state.visible} menuDisappear={this.clickHandle}/>

                <div className={styles.topRight}>
                    <button className={styles.button} onClick={this.clickHandle}>
                        <i className={`fas fa-${this.state.visible ? "times" : "bars"}`}></i>
                    </button>
                </div> */}

                <Navbar
                    bg="dark"
                    variant="dark"
                    expand="md"
                    className={styles.navbar}
                >
                    <Container fluid className={styles.container}>
                        <Navbar.Brand className={styles.brand} as={Link} to="/">
                            <img
                                src={Logo}
                                alt="logo"
                                className={styles.brandImage}
                            ></img>
                            NITK Hostels
                        </Navbar.Brand>

                        <div className={`vr ${styles.vr}`}></div>

                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            className={styles.toggleBar}
                        />

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className={`me-auto ${styles.navbarCollapse}`}>
                                <Nav.Link as={Link} to="/about">
                                    <p>About Us</p>
                                </Nav.Link>

                                <NavDropdown
                                    title="People"
                                    id="basic-nav-dropdown"
                                >
                                    <NavDropdown.Item
                                        as={Link}
                                        to="/representatives"
                                    >
                                        <p>Representatives</p>
                                    </NavDropdown.Item>
                                    <br />
                                    <NavDropdown.Item
                                        as={Link}
                                        to="/supervisor"
                                    >
                                        <p>Supervisors</p>
                                    </NavDropdown.Item>
                                    <br />
                                    <NavDropdown.Item as={Link} to="/staff">
                                        <p>Hostel Staff</p>
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown
                                    title="Hostel"
                                    id="basic-nav-dropdown"
                                >
                                    <NavDropdown.Item
                                        as={Link}
                                        to="/information"
                                    >
                                        <p>Information</p>
                                    </NavDropdown.Item>
                                    <br />
                                    <NavDropdown.Item
                                        as={Link}
                                        to="/rules-regulations"
                                    >
                                        <p>Rules and Regulations</p>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link
                                    as={(props) => (
                                        <a
                                            href={props.href}
                                            target={props.target}
                                            rel={props.rel}
                                            className={props.className}
                                        >
                                            {props.children}
                                        </a>
                                    )}
                                    href="https://iris.nitk.ac.in/hrms/hostels/dashboard"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Allotment
                                </Nav.Link>
                                <Nav.Link as={Link} to="/gallery">
                                    Gallery
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

export default Header;
