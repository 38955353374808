import React from "react";
import { useState } from "react";
import { useTransition, animated } from "react-spring";

import styles from "../../Styles/Common/HomeHeading.module.css";

const images = require.context("../../Images/Common/webp");

function HomeHeading() {
    return (
        <div className={styles.wrapper}>
            {slideShow()}
            <div className={styles.inner}>
                <p>NITK HOSTELS</p>
            </div>
            <a href="#main-home">
                <div className={styles.arrow}>
                    <span className={styles.arrowSpan}></span>
                    <span className={styles.arrowSpan}></span>
                    <span className={styles.arrowSpan}></span>
                </div>
            </a>
        </div>
    );
}

function slideShow() {
    const slideImages = [
        images("./slide01.webp"),
        images("./slide02.webp"),
        images("./slide03.webp"),
        images("./slide04.webp"),
        images("./slide05.webp"),
    ];

    const [slideIndex, set] = useState(0);

    const transitions = useTransition(slideIndex, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 3000,
        config: { duration: 1000 },
        onRest: () => set((slideIndex + 1) % slideImages.length),
    });

    return (
        <div className={styles.slideContainer}>
            {transitions(({ opacity }, item) => (
                <animated.div
                    style={{
                        position: "absolute",
                        opacity: opacity.to({
                            range: [0.0, 1.0],
                            output: [0, 1],
                        }),
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                    }}
                >
                    <div
                        key={item}
                        style={{
                            backgroundImage: "url(" + slideImages[item] + ")",
                        }}
                        className={styles.slideImg}
                    ></div>
                </animated.div>
            ))}
        </div>
    );
}

export default HomeHeading;
