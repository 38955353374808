import React from "react";
import styles from "../Styles/Staff.module.css";
import Heading from "./Common/Heading";
import StaffCard from "./Util/StaffCard";

import { staff } from "../Data/staff.json";

class Supervisor extends React.Component {
    render() {
        let items = [];
        for (let i = 0; i < staff.length; i++) {
            items.push(
                <StaffCard
                key={i}
                style={{margin: '0 auto'}}
                img={staff[i].img}
                alt={staff[i].name}
                name={staff[i].name}
                designation={staff[i].designation}
                />
            )
        }
        return (
            <>
            <Heading title="Staff" />
            <div className={styles.representatives}>
                {items}
            </div>
            </>
        );
    }
}

export default Supervisor;
