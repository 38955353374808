import React from "react";
import styles from "../../Styles/Util/RepresentCard.module.css";

const images = require.context("../../Images/People/Representatives/");

const RepresentCard = (props) => {
    return (
        <div className={styles.cardOutline}>
            <img src={ images(`./${props.img}`) } alt={props.alt} className={styles.image}/>
            <div className={styles.personInfo}>
                <div className={styles.name}>{props.name}</div>
                <div className={styles.designation}>{props.designation}</div>
                <div className={styles.contact}>
                    Contact Number: {props.number}<br /><br />
                    Email: <a
                        style={{color: 'inherit', textDecoration: 'underline'}}
                        href={`mailto:${props.email}`}>
                    {props.email}</a>
                </div>
            </div>
            <a
                href={props.contact}
                target="_blank"
                rel="noopener noreferrer"
            >
                <button className={styles.button}>
                    <p style={{color: 'white'}}>View Profile</p>
                </button>
            </a>
        </div>
    );
}

RepresentCard.defaultProps = {
    img: "bpic.png"
}
export default RepresentCard;
