import React from "react";

import Heading from "./Common/Heading";

import styles from "../Styles/Rules.module.css";

const Rules = () => {
    return (
        <>
        <Heading title="Rules and Regulations" id="heading"/>
        <div className={styles.para}>
             <p><a href="https://docs.google.com/document/d/1epoL8CZ9DeElj8RxSOsiBPOY7rn0F6ci/edit"><strong> RULES AND REGULATIONS PDF </strong></a></p>

            <p><strong>I. ACCOMMODATION</strong></p>
            <ol>
                <li>
                    Only bonafide students of the Institute (on current
                    rolls) are eligible to get accommodation in NITKS
                    Hostels.
                </li>
                <li>
                    The hostel accommodation and mess allotment must be done by paying the hostel establishment fee and mess advance fee via the IRIS portal.
                </li>
                <li>
                    The Hostel fee payment schedule is the same as the institute fee Schedule. A fine of ₹100/- per day will be charged to students for delay in Hostel fee payment. The fee structure is available at <a href="https://www.nitk.ac.in/fee-structure"> https://www.nitk.ac.in/fee-structure </a>

                </li>
                <li>
                    The room and mess allotment process is made online through the IRIS portal for the convenience of all.

                </li>
                <li>
                    The students can choose their rooms in designated hostel blocks as per the hostel room allotment policy. They can choose their mess from the available options in the IRIS  portal.
                </li>
                <li>
                    All residents must occupy the room officially allotted to them. Mutual exchange of rooms is allowed once per year with the approval of the Block warden. Any additional mutual exchange request in the same year is allowed on payment of ₹500. The student needs to update all of this in IRIS through the hostel office.
                </li>
                <li>
                    If any resident is found occupying another resident room without proper permission, a penalty of ₹1000  will be charged.

                </li>
                <li>
                    For differently-abled students, accommodation will be provided on the ground floor of the hostel block to facilitate smooth mobility.
                </li>
                <li>
                    Residents shall keep their rooms neat and clean. Room furniture such as cot, chair, table, and electrical fittings such as ceiling fan, tube lights, etc., must be maintained by the students in good condition.
                </li>
                <li>
                    Residents are not permitted to keep costly jewelry, expensive things, and large cash sums with them; the hostel authorities will not be responsible for their loss, theft, or missing items whatsoever.
                </li>
                <li>
                    An undertaking of indemnity should be signed by the students before occupying a room.
                </li>
                <li>
                    If a student observes any defacement of walls, damage or defects in the furniture issued to him/her or in the permanent fittings in his / her room or finds anything missing at the time he/ she occupies the room. In such cases, it will be their duty to bring it to the notice of the hostel supervisor and record it in the undertaking, failing which it will be presumed that everything was in order at the time of occupying the room.
                </li>
                <li>
                   Residents will be held responsible for the defacement of walls, damage to the room property (fan, furniture, fittings and fixtures, etc) provided to them. They will be held responsible for any breakage, repair, or loss caused to the hostel and mess property. In such cases, the students will have to pay the cost of such damages as decided by the Hostel authorities.
                </li>
                <li>
                    Residents are informed to secure their rooms with their locks. They are advised to keep a duplicate key. In case of loss of room key, or cupboard key, no student is allowed to break the hostel room lock or cupboard lock, etc. Students must secure permission from the respective Block Warden to break any lock.
                </li>
                <li>
                Expensive items like cameras, mobile phones, laptops, and other electronic gadgets should be kept inside the cupboard for safety. Residents should lock their  cupboards and rooms properly. Each roommate must keep a key to their room, particularly in the case of double/triple seated rooms. A hostel resident will be entirely responsible for all his possessions, including their laptop/ Computer / Electronic gadgets, etc. The institute or hostel will not be liable for any loss incurred.

                </li>
                <li>
The residents must be careful in using water and electricity in their respective blocks. Students leaving their room for classes/other activities must switch off the fan and lights, failing which a fine of ₹500/- for fan/tube light/bulb per day will be charged to all persons residing in the room. Repeat offenders attract hefty fines/expulsion from the hostel as decided by the hostel office.
                    
                </li>
                <li>
                  The use of electrical/ electro-mechanical equipment gadgets and appliances, such as electric stoves/heaters/refrigerators / infrared cookers/washing machines, is strictly prohibited by the boarders in or outside the rooms. Private cooking in the rooms or anywhere in the hostel is strictly forbidden. Any such appliance found in a hostel room will be confiscated. Such students must pay a monetary fine and face disciplinary action as per the hostel authorities. However, immersion water heaters may be used only in bathrooms.
                </li>
                <li>
                    The resident shall not move any furniture from its allotted room and also not damage it in any way. If there are any additional items in a hostel room, the occupant of the room shall hand them over to the Warden, failing which they will be charged a penalty rent as decided by the Warden.
                </li>
                <li>
                    Residents are duty-bound to report to the supervisor / Warden/ Security Personnel/ Professor in charge of hostels in case they notice any unwanted incident or undesirable activity in the hostel or campus.

                </li>
                <li>
                    Day scholars and other relatives of boarders are not permitted to stay in the hostel as guests. Entertaining unauthorized guests will lead to expulsion from the hostel.  In addition, such cases will be referred to the Hostel Disciplinary Committee for further action. The Boarder's parents/guardians/spouses can avail the Institute guest house facility on a payment basis as per the Guest House accommodation rules.
                </li>
                <li>
                    Residents should not play cricket, football, etc., in the corridors, common halls, or front yards of hostels. Residents who contravene this rule will be required to surrender their equipment, and a fine of ₹1000/- to ₹5000/-will be imposed on them. It causes damage to the hostel property but also disturbs the peace of the surroundings. A heavy penalty will be charged and collected from all Hostel Wing/ Block students in case of any damage to the hostel property.
                </li>
	       <li>
	    Inmates are not permitted to stick posters/ pictures of any type (including God and Goddess)/paintings/drawings or to write slogans/graffiti on the hostel doors and walls.
	    </li>
	    <li>
	    Students are not allowed to paint their rooms on their own. The hostel office will carry out painting work periodically.
	    </li>
	    <li>
     Boarders are required to vacate their rooms as per the notice issued at the end of the semester. In case of non-vacation of rooms, a fine of ₹ 500/- per day will be levied. The residents should get written permission from the Block warden/Professor In-charge, NITK Hostels in case of any genuine reason to extend the stay.
	    </li>
	    <li>
	    Hostel accommodation is available to UG students for four years, PG students (M. Tech/M.B. A/M.C.A. and M.Sc.) for two/three years, and Ph.D. scholars for four years.
	    </li>
	    <li>
	    PG students and Ph.D. scholars will be allowed to continue to reside in the hostel during summer vacation only with the recommendation of their guides countersigned by the Head of the Department concerned and the Warden's permission.
	    </li>
	    <li><b>
	    Boarders are requested to report the room vacating date and sign in the room allotment register kept in the hostel office at the end of the academic year failing which they are liable for a fine of ₹ 1000/- per Week. For any reason, boarders vacating the room in the middle of the semester should inform the Hostel office in writing. Residents must cancel their mess card by visiting the hostel office when they vacate the room failing which the mess bill will be charged as applicable.
	   </b>
	    </li>
	    <li>
	    Unlawful assembly and gathering in a hostel room are strictly prohibited.
	    </li>
	    <li>
	    Laundry services are provided on a payment basis to all hostel inmates.
	    </li>

            </ol>
            <p>
            Amount of fine for the violation of rules and regulations will be as decided by the Council of Wardens/ Professor In-charge, NITKS Hostel from time to time and imposed on the defaulters.   
            </p>

            <p><strong>II MESS RULES</strong></p>
            <p>
                The hostels are meant for both boarding and lodging. No
                one is permitted to use the hostel for boarding or
                lodging only. There are 11 messes operating in various
                hostel blocks to cater the needs of inmates. Out of
                which two messes for Girl’s Hostel and Five vegetarian
                messes and Four non-veg messes for boy’s hostel.
            </p>
            <ol>
                <li>
                    Once a student is admitted to a Hostel he /she is
                    deemed to have become a member of the hostel mess
                    until he / she officially vacates the Hostel.
                </li>
                <li>
                    Mess changes will be allowed only by the concerned
                    ensuring that the strengths of all the messes are
                    even or balanced. Change of mess cannot be insisted
                    upon as a matter of right. Mess changes are allowed
                    at the end of every month.
                </li>
                <li>
                    Residents who do not join any of the messes during
                    the semester will be considered as defaulters and a
                    fine of Rs.4000.00 per month will be imposed on
                    them.
                </li>
                <li>
                    Exemption from compulsory membership of the mess can
                    be granted only on medical grounds duly approved by
                    the Professor In-charge, NITKS Hostels and will be
                    considered from the date of submission of medical
                    certificate signed by the Resident Medical Officer
                    of the institute, to the hostel office.
                </li>
                <li>
                   Food will not be served in rooms, and the inmates are not supposed to take food from the dining hall to their rooms. No hostel resident is permitted to carry any articles/utensils outside the hostel/mess premises and even to their room. If the offense is committed for the second time, a fine of ₹ 1000/- will be imposed on them. If the concerned resident is found repeating the mistake, strict disciplinary action shall be taken against them.
                </li>
                <li>
                    If an inmate is ill and is unable to move from the
                    room, he/she will be served special food (Bread and
                    Milk only) inside the room. Prior intimation to Mess
                    Assistant / Mess In-charge is necessary for availing
                    special food service. Inmates are advised to write
                    name and room number of sick student, in the
                    register available with the Mess Assistant / Mess
                    In-charge. Details of utensils taken out from the
                    mess for serving special food shall be recorded in
                    the register available with the Mess Assistant /
                    Mess In-charge.
                </li>
                <li>
                    Mess rebate will be provided to the residents as per
                    NITKS rules and regulations.
                </li>
                <li>
                    Absence from messes during official camps (N.C.C.
                    Survey, N.S.S. training, project etc.) may be
                    permitted as per the Hostel norms, with prior
                    intimation to the hostel norms, with prior
                    intimation to the Hostel Office.
                </li>
                <li>
                    Residents are eligible for a mess rebate if they are absent for a continuous stretch of 5 days or more. The hostellers are required to submit the duly filled mess rebate form at least one week in advance. A valid leave approval must be attached to the application.   Block wardens are authorized to approve the mess rebate application. Incomplete applications will be rejected.  The hosteller may contact the hostel office for any further information regarding mess concession.  
                </li>
                <li>
                   Mess concession is offered to economically disadvantaged students every semester. Residents must apply using the prescribed format.
                </li>
                <li>
                    Food will be served only in the dining hall of the
                    mess to members as per the following timings;
                    <table
                        width="100%"
                        border="1"
                        cellSpacing="10"
                        cellPadding="10"
                        style={{border: "1px solid lightgray"}}
                    >
                        <tbody>
                            <tr>
                                <th scope="col">&nbsp;</th>
                                <th scope="col">Weekdays (Mon-Fri)</th>
                                <th scope="col">Weekends (Sat-Sun)</th>
                            </tr>
                            <tr>
                                <td>Breakfast</td>
                                <td>07:00 AM to 9:30AM</td>
                                <td>08:00 AM to 10:00 AM</td>
                            </tr>
                            <tr>
                                <td>Lunch</td>
                                <td>12:00 AM to 01:45 PM</td>
                                <td>12:00 noon to 02:00 PM</td>
                            </tr>
                            <tr>
                                <td>Tea</td>
                                <td>04:30 PM to 06:00 PM</td>
                                <td>04:30 PM to 06:00 PM</td>
                            </tr>
                            <tr>
                                <td>Dinner</td>
                                <td>07:30 PM to 09:15 PM</td>
                                <td>07:30 PM to 09:15 PM</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    Wasting food is a social crime. For the first time
                    offence of wasting food by a boarder, warning will
                    be issued to him/her. If the offence is committed
                    for second time a fine of Rs.500/- will be imposed
                    on him/her. If the concerned boarder is found
                    repeating the mistake, strict disciplinary action
                    shall be taken against him/her.
                </li>
                <li>
                    During hostel stay, students may place  orders for door-delivery of  food from establishments serving inside the campus.   However food aggregators like Swiggy, Zomato   etc. are not allowed to enter the hostel premises.
                </li>
                <li>
                    Hostellers  should not take food, soft drinks, or snacks from unhygienic shops and roadside vendors to avoid spreading infection among fellow boarders.
                </li>
            </ol>

            <p><strong>III MEDICAL FACILITY</strong></p>
            <p>
                Medical facilities are provided at the Institute
                dispensary for routine health problems. Following
                hospitals are also recognized for the medical treatment.
            </p>
            <ol>
                <li>
                    A. J. SHETTY, HOSPITAL & RESEARCH CENTRE, Kuntikana,
                    Mangalore.
                </li>
                <li>
                    FATHER MULLER CHARITABLE INSTITUTION, Kankanady,
                    Mangalore.
                </li>
                <li>K.M.C. HOSPITAL, Manipal, Udupi.</li>
                <li>K.M.C. HOSPITAL, Attavar, Mangalore.</li>
                <li>
                    K.M.C. HOSPITAL (MANIPAL HEALTH SYSTEMS PVT. LTD.)
                    Dr. Ambedkar Circle, Mangalore.
                </li>
                <li>
                    MANGALORE NURSING HOME, Upper Bendoor, Mangalore.
                </li>
               <li> Chirashree Nursing Home, Surathkal.
</li>
                <li>
                    PRASHANTH NETRALAYA, Bunts Hostel Road, Mangalore.
                </li>
                <li>S.D.M. AYURVEDIC HOSPITAL, Udyavara, Udupi.</li>
                <li>S.D.M. AYURVEDIC HOSPITAL, Bejai, Mangalore.</li>
                <li>
                    S.D.M. YOGA & NATURE CURE HOSPITAL,Ujire,
                    Dharmasthala.
                </li>
                <li>
                    S.D.M. NATUROPATHY & YOGA HOSPITAL, Pareeka-Parkala,
                    Manipal, Udupi.
                </li>
                <li>
                    TEJASVINI HOSPITAL, Kadri Temple Road, Mangalore.
                </li>
                <li>UNITY HEALTH CENTRE, Highlands, Mangalore.</li>
                <li>YENOPOYA HOSPITAL, Kodialbail, Mangalore.</li>
                <li>
                    SRINIVAS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH
                    CENTRE, Srinivasnagar, Mukka, Surathkal.
                </li>
            </ol>
            <p>
                Any other Hospital in Mangalore region may be considered
                only on prior recommendation by R.M.O. and approval of
                Block Warden.
            </p>

            <p><strong>IV. General Rules</strong></p>
            <ol>
                <li>
                    No hostel inmate shall take the law into his/her own
                    hands. If any of the hostel inmate finds that other
                    students are indulging in any undesirable activity
                    or causing inconvenient or physical injury to
                    him/her or to the other hostel inmates, he/she
                    should lodge a complaint in writing with the Warden
                    concerned.
                </li>
                <li>
                    Residents shall not get involved in arguments or
                    otherwise interfere with the employees of the
                    concerned Hostel Block Warden.
                </li>
                <li>
                    Smoking, chewing tobacco, pan masala, playing cards,
                    consumption of alcohol, entering campus in drunken
                    condition, possession of alcoholic beverages and
                    manufacturing, possessing, selling, using of any
                    illegal drugs/narcotic substance, will lead to serve
                    punishment such as expulsion from hostel, semester
                    drop or expulsion from the academic program/denial
                    of Placement services of the Institute or lodge a
                    police complaint and anyone present during such
                    activity and caught along with people indulging in
                    such misuse will be treated on par with those
                    indulging in it.
                </li>
                <li>
                    No noise/loud music should be heard from outside
                    resident’s room at ANY TIME. Loud music can be
                    extremely disturbing and annoying and lack of sleep
                    can effect academic performance, especially at
                    examination time. Amplified musical instruments and
                    drum kits are NOT allowed in the hostel rooms.
                    Residents who like playing music loudly should use
                    headphones. Residents who contravene this rule will
                    be required to surrender their equipment or
                    instrument and a fine of Rs.5000/-will be imposed on
                    them.
                </li>
                <li>
                    Boarders must strictly follow the timings of
                    TV/common reading rooms, as notified time to time.
                    During examination periods any resident who disturbs
                    other residents may be asked to leave the hostel
                    till the end of their examination period.
                </li>
                <li>
                    Keeping of lethal weapons like sticks, rods, chains
                    etc. in the hostel rooms is strictly prohibited.
                </li>
                <li>
                    Ragging: Ragging in any form is BANNED. It is a
                    cognizable offence and violation will invite action
                    as per law of the land, in addition to rustication
                    from the Institute. Being a silent spectator and not
                    reporting / stopping others indulging in ragging is
                    also an offence and will invite similar disciplinary
                    action. Accepting /undergoing ragging and not
                    reporting ragging is also an offence. Please report
                    any incident immediately to any member of the
                    Anti-Ragging committee/Warden /Professor in-charge
                    NITKS Hostels/Security Personnel/Deans/Director
                    directly at any time of the day /night.
                </li>
                <li>
                    The use of motor vehicles such as Car, Motor cycle,
                    Scooters, Mopeds, etc. by hostel residents is
                    strictly prohibited inside the Hostel premises.
                    Residents violate this rule will be required to
                    surrender their vehicle and a fine of Rs. 20,000/-
                    will be imposed on them.
                </li>
                <li>
                    Students shall not remain absent from their Hostels
                    during night between 01.00 AM to 06.00 AM, without
                    the prior permission of the Warden /Professor
                    in-charge NITKS Hostels. In case of any emergency,
                    they should take permission from the Block Warden
                    /Professor in-charge NITKS Hostels. The students are
                    required to carry their identity cards with them
                    when they move inside the campus.
                </li>
                <li>
                    First year students are not allowed to be outside
                    the hostel after 07.00 PM, for any reason without
                    specific and prior approval of Warden and making
                    proper entries in the register.
                </li>
                <li>
                    The inmates of the hostel should not leave the
                    hostel premises on holidays for the purpose of
                    excursion or picnic without prior permission of the
                    Professor in-charge, NITKS Hostels. However, for any
                    accident or fatality that may occur during
                    picnic/excursion, the responsibility does not lie
                    with the institute or hostel authorities.
                </li>
                <li>
                    No student can approach/speak directly to the
                    media/police nor shall they allow them to visit the
                    premises of the hostel. Complaint to the Professor
                    In-charge, NITKS Hostels/Institute Authorities and
                    Police Department must be submitted through the
                    Warden.
                </li>
                <li>
                    The room of any student in the hostel can be
                    inspected at any time by the Warden or any
                    authorized member of NITKS Hostels. Inmates are
                    advised to keep their ID cards with them and show
                    them on demand.
                </li>
                <li>
                    Female visitors and girl students are not permitted
                    to visit the boy’s Hostel and male visitors
                    (Visitors mean Parents/Guardians) are not permitted
                    into the girl’s Hostel. In special circumstance,
                    prior permission is required from the Professor
                    In-charge, NITKS Hostels.
                </li>
                <li>
                    All the residents are to note that
                    electrical/carpentry/plumbing complaints should be
                    written in the complaint register available in the
                    hostel office. In case the complaint is not attended
                    in two or three days, students can contact Block
                    Warden /Professor in-charge NITKS Hostels.
                </li>
                <li>
                    Residents shall treat the housekeeping and Hostel
                    staff with due courtesy at all times. Service of the
                    housekeeping staff shall NOT be utilized for private
                    or personal work. No tips in cash or other kind will
                    be given to housekeeping staff of the hostel.
                </li>
            </ol>

            <p>
                ANY OF THE RULES CONTAINED IN THIS BROCHURE MAY BE
                CHANGED WITHOUT PRIOR NOTICE BY THE HOSTEL
                ADMINISTRATION.
            </p>
            <p style={{textAlign: "center"}}>
                <strong>SPECIAL NOTE</strong>
            </p>
            <p>
                <strong>RAGGING:</strong> No student shall indulge in
                any activities such as ragging under the pretext of
                initiation ceremonies for new students and indulge in
                any form of intimidation, wrongful confinement or
                causing physical harm or injury or any kind of annoyance
                on using abusive language to touch upon communal,
                religious, linguistic in such other matters. Admissions
                of students who indulge in such activities are liable to
                be cancelled, besides punitive action according to law.
            </p>
            <p style={{textAlign: "center"}}>
                HOSTEL IS HOME AWAY FROM HOME, <br />PLEASE TREAT HOSTEL
                AS YOUR DEAR HOME
            </p>
            <p>
                It is the responsibility of inmates to do their best for
                maintaining discipline, peace and harmony in the Hostel.
                The senior students are advised to set good examples to
                their juniors. Any activity of the inmates observed to
                be prejudicial and detrimental to the smooth and
                peaceful functioning of the Institute Hostel will be
                viewed seriously by the management and disciplinary
                action will be taken against those found guilty.
            </p>
            <p style={{textAlign: "center"}}>
                PROFESSOR IN-CHARGE<br />HOSTEL AFFAIRS<br />NITKSURATHKAL
                HOSTELS
            </p>
        </div>
        </>
    );
}

export default Rules;
