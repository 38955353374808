import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const oldPathname = useRef(pathname);

  const disablePath = /(information\/.+)/g;

  useEffect(() => {
    if(disablePath.test(pathname) || disablePath.test(oldPathname.current)) {
        // Do nothing
    } else {
        window.scrollTo(0, 0);
    }

    oldPathname.current = pathname;
  }, [pathname]);

  return null;
}
