import React from "react";
import styles from "../../Styles/Util/StaffCard.module.css";

const images = require.context("../../Images/People/Others/");

const StaffCard = (props) => {
    return (
        <div className={styles.cardOutline}>
            <img src={ images(`./${props.img}`) } alt={props.alt} className={styles.image}/>
            <div className={styles.personInfo}>
                <div className={styles.name}>{props.name}</div>
                <div className={styles.designation}>{props.designation}</div>
            </div>
        </div>
    );
}

StaffCard.defaultProps = {
    img: "bpic.png"
}
export default StaffCard;
