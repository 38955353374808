import React from "react";
import { Link } from "react-router-dom";
import styles from "../../Styles/Common/Footer.module.css";

const getGeneralLinks = () => {
    return (
        <div className={`${styles.row} ${styles.footRow}`}>
            <div>
                <h4><b>General Links</b></h4>
            </div>

            <div>
              <p> <a href="https://www.nitk.ac.in/" target="_blank" rel="noopener noreferrer">NITK Website</a> <br />
                <a href="https://alumni.nitk.ac.in/" target="_blank" rel="noopener noreferrer">Alumni</a><br />
                <a href="https://iris.nitk.ac.in/hrms/placement" target="_blank" rel="noopener noreferrer">Career Development Centre</a> <br />
                <a href="http://ccc2.nitk.ac.in/" target="_blank" rel="noopener noreferrer">Central Computer
                  Centre</a> <br />
                <a href="http://library.nitk.ac.in/" target="_blank" rel="noopener noreferrer">Central Library</a> <br />
                <a href="http://reo.nitk.ac.in/" target="_blank" rel="noopener noreferrer">RE Office</a>
              </p>
            </div>
        </div>
    );
}

const getQuickLinks = () => {
    return (
        <div className={`${styles.row} ${styles.footRow}`}>
            <div>
                <h4><b>Quick Links</b></h4>
            </div>

            <div>
                <p>
                <Link to="/team">Contact Us</Link> <br />
                <a href="https://teqip.nitk.ac.in/" target="_blank" rel="noopener noreferrer">TEQIP</a> <br />
                <a href="http://vtour.nitk.ac.in/" target="_blank" rel="noopener noreferrer">Virtual Tour</a> <br />
                    <a href="https://prism.nitk.ac.in/" target="_blank" rel="noopener noreferrer">PRISM NITK</a> <br />
                    <a href="http://reo.nitk.ac.in/content/hostel-complaint-form" target="_blank" rel="noopener noreferrer">Hostel Complaints
                      Portal</a> <br />
                    <a href="https://iris.nitk.ac.in/hrms/" target="_blank" rel="noopener noreferrer">IRIS Portal</a> <br />
                </p>
            </div>
        </div>
    );
}

const getContact = () => {
    return (
        <div className={`${styles.row} ${styles.footRow} ${styles.location}`}>
            <div>
                <p>
                  NITK Hostels Trust
                <br/>
                      Surathkal,
                      Mangalore, India - 575025

                    <br />
                      0824 2474000
                    <br />
                      <a href="mailto:chiefwarden@nitk.edu.in">chiefwarden@nitk.edu.in</a>
                </p>
            </div>
        </div>
    );
}

// const getMap = () => {
//     return (
//         <div className={styles.mapContainer}>
//             <div className="mapouter">
//                 <div className="gmap_canvas">
//                     <iframe title="NITK on Google Map" width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=national%20institute%20of%20technology%20karnataka&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
//                 </div>
//             </div>
//         </div>
//     );
// }

const Footer = () => {
    return (
        <div className={styles.footerContainer}>
          {getContact()}
          {getQuickLinks()}
          {getGeneralLinks()}
        </div>
    );
}

export default Footer;
