import React from "react";
import styles from "../../Styles/Common/Heading.module.css";

const Heading = (props) => {
    return (
        <section className={`wrapper style3 ${styles.box}`}>
            <div style={{width: '100vw'}} className="inner">
                <header>
                    <p style={{ color: '#fff', textAlign: 'center' }}>
                        {props.title}
                    </p>
                </header>
            </div>
        </section>
    );
}

export default Heading;
