import React from "react";
import styles from "../../Styles/Util/Card.module.css";

const Card = (props) => {
    return (
        <div className={styles.box}>
            <div className={styles.title}>
                {props.title}
            </div>

            <div >
                {props.children}
            </div>
        </div>
    );
}

export default Card;
