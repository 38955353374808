import React from "react";
import HomeCard from "./Util/HomeCard";

import styles from "../Styles/Home.module.css";
import HomeHeading from "./Common/HomeHeading";

import { announcements } from "../Data/homeCards.json";

function getAccouncements() {
    let x = [];

    for(let i of announcements) {
        x.push((
            <HomeCard key={i.title} title={i.title} content={i.content} />
        ));
    }

    return x;
}

class Home extends React.Component {
    state = {
        cards: [
            {
                title: "COVID 19 Protocols:",
            },
            {
                title: "Reports and Documents",
            },
        ],
    };

    render() {
        return (
            <>
                <HomeHeading />
                <div
                    id="main-home"
                    style={{ height: "5vh", width: "100vw" }}
                ></div>

                <div className={styles.cardRow}>
                    {getAccouncements()}
                </div>

                {/* <div className={styles.cardRow}>
                    <HomeCard title={this.state.cards[0].title}>
                        We are taking utmost precautions to curb the spreading
                        of novel CORONA 19 viruses by installing hand sanitizers
                        and contact less thermometer at the entry of each
                        hostel, at hostel office and at mess. The mess workers
                        and hostel staff will be thoroughly checked for symptoms
                        before entering the mess/hostel premises. A separate
                        hostel block and a mess has been reserved for
                        symptomatic students for the quarantine purpose and a
                        team of wardens and medicos has been formed as the first
                        responders to handle such situations.
                    </HomeCard>

                    <HomeCard title={this.state.cards[1].title}>
                        <ul className={styles.ulTypeA}>
                            <li>
                                1.
                                <a
                                    href="https://www.nitk.ac.in/document/attachments/2650/Fee_Structure_2021-2022.pdf"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className={styles.link}
                                >
                                    Hostel Fee Structure 2021-22
                                </a>
                            </li>
                            <li>
                                2. 
                                <a
                                    href="https://drive.google.com/file/d/1GZeV2Hg__mUJQGacVXLDaU1142WX7RXQ/view?usp=sharing"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className={styles.link}
                                >
                                    Hostel Report 2021-2022
                                </a>
                            </li>
                        </ul>
                    </HomeCard>

                    <HomeCard title="Coliseum Tournament">
                        Congratulations to all the winners of the Coliseum
                        Tournament! You can find the branch-wise distribution
                        here:
                        <br />
                        <br />
                        <a
                            href="https://google.com/spreadsheets/d/1tV0qYz9w7Slh2dAmjABoxYBnWwPSA6440yXEyx2zPFY/edit#gid=0"
                            target="_blank"
                            rel="noreferrer noopener"
                            className={styles.link}
                        >
                            Overall Championship Leaderboard
                        </a>
                    </HomeCard>

                    <HomeCard title="Floodlight Tournament">
                        The Phoenix Committee is another students committee
                        which looks after the sports activities for the
                        residents of the hostels and provides indoor game
                        facilities to them. The phoenix recreation committee
                        organized the Floodlight Tournament during March 24th to
                        26th 2022
                    </HomeCard>

                    <HomeCard title="Panache Competition">
                        Crescendo, a committee which organizes co-curricular
                        activities for the students, is managed by a group of
                        elected students from the hostel representatives. The
                        Crescendo has organized PANACHE 2022 on March 27th 2022
                        which attracted many students from various branches for
                        the competitions
                    </HomeCard>

                    <HomeCard title="Taskforce NITK">
                        Task Force is a platform for students to showcase their
                        talent in administrative work at NITK Surathkal. It has
                        been instituted in NITK Hostel Administration to serve
                        the well-being of all students
                    </HomeCard>
                </div> */}
                <div>
                    <iframe
                        className={styles.frame}
                        src="https://www.google.com/maps/embed?pb=!4v1651155937220!6m8!1m7!1sCAoSLEFGMVFpcE43T3NQMWxKamwxVDlVVzkwLW5OSzhDRUVlSWpyYnV4RGI4S0Nw!2m2!1d13.01082304082908!2d74.79387880942004!3f96.59086466171773!4f17.22294867416089!5f0.7820865974627469"
                        width="800"
                        height="550"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="360 view"
                    ></iframe>
                </div>
            </>
        );
    }
}

export default Home;
