import React from "react";
import styles from "../../Styles/Util/AboutCard.module.css";

const AboutCard = (props) => {
    return (
        <div className={styles.cardContainer}>
            <div className={styles.heading}>
                <p>{props.heading}</p>
                <img src={props.image} className={styles.image} alt="about" />
            </div>
            <div className={styles.info}>
                <div className={styles.para1}>{props.para1}</div>
                <div className={styles.para2}>{props.para2}</div>
                <div className={styles.para3}>
                  {props.para3}
                    <div className={styles.footer}>
                        <strong>{props.name}</strong> <br />
                        {props.designation} <br />
                        National Institute of Technology Karnataka, Surathkal
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCard;
