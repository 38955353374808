import React from 'react';

import Heading from "./Common/Heading";
import styles from "../Styles/Gallery.module.css";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { gallery } from "../Data/gallery.json";

const imageProv = require.context("../Images/Gallery/");

class Gallery extends React.Component {
  render() {
    let images = [];
    for (let i = 0; i < gallery.length; i++) {
      images.push(
        <div>
          <img src={imageProv(`./${gallery[i].image}`)} alt={gallery[i].legend} />
          <p className="legend">{gallery[i].legend}</p>
        </div>
      );
    }

    return (
      <>
        <Heading title="Gallery" />
        <div className={styles.gallery}>
          <Carousel showArrows={true}>
            {images}
          </Carousel>
        </div>
      </>
    );
  }
};

export default Gallery;
