import React from "react";
import styles from "../Styles/About.module.css";

import Director from "../Images/People/About/Director.jpg";
import ChiefW from "../Images/People/About/chief-warden.jpg";

import AboutCard from "./Util/AboutCard";
import Card from "./Util/Card";
import Heading from "./Common/Heading";

const getDirectorMsg = () => {
    return (
        <AboutCard
            heading="Director's Message"
            para1="NITK has a long legacy in southern India of               imparting education to move form thinking about the             greatest problems affecting society to understanding          and solving them and this has been our long standing          vision. We are built of cutting edge engineering and science infrastructure, fostering highly motivated faculty and staff members to help students leverage each other's developments and take the institute to the next level. Years of unrelenting contributions  of all the stakeholders has let NITK evolve into an institute of national importance."
            para2="Our primary mission is to provide resources and state-of-the art programs that will help in our student’s professional development. The central   Research facility (CRF) and Career Development Centre (CDC) are a few initiatives the institute has taken to engage the students to the real-time issues and make the students stronger candidates for national, and international workforce as well as graduate and higher professional programs."
            para3=" Our focus is to seek and continuously improve our performance and to grow into one of the best institutions for engineering education in the country."
            name="Prof. B Ravi"
            designation="Director"
            image={`${Director}`}
        />
    );
};

const getChiefWardenMsg = () => {
    return (
        <AboutCard
            heading="Chief Warden's Message"
            para1="The cost of discipline is always less than the price of Regret; So self-discipline is the biggest investment for success in life. My warm greetings to all the National Institute of Technology Karnataka, Surathkal hostel students, their parents living in India, and other parts of the world, and esteemed visitors of this website."
            para2="It gives us mammoth inclination to welcome you all to be a part of the NITK family that strives to provide students a homely and healthy atmosphere with complete Safety and security in the hostel. At the hostels of NITK, we not only take efforts to provide its students a neat and clean environment and a comfortable place to live in, but encourage them to live in discipline. We understand discipline is the key to success and career building, hence we promote self-discipline among students."
            para3=" Our focus is to seek and continuously improve our performance and to grow into one of the best institutions for engineering education in the country. My warm wishes are with you for your outstanding academic careers."
            name="Dr. Pushparaj Shetty D"
            designation="Chief Warden"
            image={`${ChiefW}`}
        />
    );
};

const chooseUs = () => {
    return (
        <Card
            title="Why Choose Us"
            children={
                <>
                    <p>
                        NITK is located in Mangalore City along
                        Kanyakumari-Mumbai National Highway 66. It is well
                        connected by road, rail and by air to major cities in
                        India and few international destinations. The Institute
                        is located amid 300 acres of sylvan surroundings with
                        the picturesque Western Ghat Mountains.
                    </p>
                    <p>
                        It is ranked among Top Engineering Institutes in India. NITK, Surathkal. It has achieved rank 12 in <a href="https://www.nitk.ac.in/nirf">NIRF ranking of Engineering Colleges in India in 2023</a>.
                    </p>
                    
                    <br />
                    <div className={styles.para}>

                        <ol>
                            <li>
                                One of the oldest and pioneer Technical Institutions
                                in India started in 1960.
                            </li>
                            <li>
                                World class infrastructure and highly equipped labs
                                and classrooms with all modern amenities.
                            </li>
                            <li>
                                Mega hostel blocks with state of the art facilities
                                and Wi-Fi Connectivity.
                            </li>
                            <li>
                                Modern international standard swimming pool,
                                gymnasium and individual standard fields for all
                                major games and sports.
                            </li>
                            <li>
                                Rated as one of the Best Engineering Colleges in
                                India.
                            </li>
                            <li>
                                <p>
                                    Linkages and Collaborations with various leading
                                    International and National Institutes, Industries and
                                    Organizations across the Globe.
                                </p>
                            </li>
                        </ol>
                    </div>

                </>
            }
        />
    );
};

const generalInfo = () => {
    return (
        <Card
            title="General Information"
            children={
                <>
                    <p>
                        NITK is located in Mangalore City along
                        Kanyakumari-Mumbai National Highway 66. It is well
                        connected by road, rail and by air to major cities in
                        India and few international destinations. The Institute
                        is located amid 300 acres of sylvan surroundings with
                        the picturesque Western Ghat Mountains.
                    </p>
                    
                    <br />
                    <div className={styles.para}>
                        <ol>
                            <li>Year of established : 1960</li>
                            <li>No. of faculty : 200</li>
                            <li>No. of Teachers : 100</li>
                            <li>Male Hostel Seat : 2000</li>
                            <li>Female Hostel Seat : 600</li>
                        </ol>
                    </div>
                </>
            }
        />
    );
};

const About = () => {
    return (
        <>
            <Heading title="About Us" />
            <div className={styles.row}>
                {getDirectorMsg()}
                {getChiefWardenMsg()}
                {chooseUs()}
                {/* {generalInfo()} */}
            </div>
        </>
    );
};

export default About;
